body {
  height: 800px;
}

html {
  font-size: 16px;

}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  animation: fade-in 1.5s ease-in !important;
  background-color: #1d1d1b !important;
}

/*--------Navbar----------*/

.ArquaIcon {
  height: 70px;
  width: 140px;
}

.navbar {
  --mdb-navbar-box-shadow: 0 4px 12px 0 rgb(235 142 9 / 61%), 0 2px 4px#51443a8a !important;
  background-color: #1d1d1b !important;
  color: white;
}


.dropdown-toggle {
  color: white !important;

}

.nav-link {
  color: white !important;
}


.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  border: black !important;
}



@media(min-width:1200px) {


  .navBrand {
    margin-left: 300px;
  }

  .navItems {
    margin-left: 400px;

  }

}

@media(min-width:576px) {

  .navItems {
    margin-left: 100px;
  }
}



/*----------HOME-----------*/

.containerHome {
  margin-top: 150px;
}

.pHome {
  font-size: 15px;
}

.eventosContainer {
  background-color: #1d1d1b !important;
  margin-right: 60px !important;
}

@media (min-width: 992px) {
  .col-lg-8 {
    padding-right: 100px !important;
  }

}

.cardSize {
  height: 100px;
  width: 100px;
}





/*-------Contact-------------*/

.textMesagge {
  height: 200px !important;
  width: 300px !important;
}

/*-------Pago--------------*/

.containerPago {
  margin-top: 150px;
}

@media(min-width:576px) {

  .containerPago {
    padding-right: 100px;
  }
}